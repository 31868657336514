/* This file provides the data for the 'fake' rendered Visual (VR) Blog Post
 ** It acts as a blog post, although it routes the user to the TSX component for Visual (VR) .
 */

export const VRPost = {
  id: "vr",
  title: "Practical VR for Adaptive Re Use",
  author: "Robotic Imaging",
  date: "2024-4-2",
  tags: ["VR", "As-Built Documentation", "Point Cloud", "LiDAR"],
  mediaSrc:
    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FVR-Page-Header.jpeg?alt=media&token=bb2dd482-ffd3-42bb-9f63-3bf50af69b43",
  fakePost: true,
  excerpt:
    "Robotic Imaging harnesses the power of Virtual Reality (VR) to create, store, and share visually-rich 3D models of physical spaces.",
  to: `/vr-blog`,
};
