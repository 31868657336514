import { styled } from "@mui/material";
import { breakpoint } from "../../../components/styled-components/Breakpoints/breakpoints";

export const SignupFlexContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  ${breakpoint(
    "xlarge+",
    `
    display: flex;
    flex-direction: row;
    position: relative;
    right: 0.5vw;
    align-items: center;
    margin: 0 auto;
    `
  )}
  ${breakpoint(
    "large-",
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    `
  )}

  ${breakpoint(
    "medium",
    `
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    `
  )}
`;

export const SignupPrompt = styled("span")`
  font-size: 15px;
  color: #ffb310;
`;

export const SignupWrapper = styled("div")`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 5px 10px;
  border: 2px solid #ffb310;
  border-radius: 10px;
  background-color: black;
  transition: opacity 0.4s;
  width: 276px;

  ${breakpoint( 
    "medium-", 
  `
  `

  )}

  
`;

export const EmailInput = styled("input")`
  font-family: Helvetica;
  font-size: 15px;
  color: #ffb310;
  display: flex;
  height: 45px;
  background-color: black;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const SignupButton = styled("button")`
  background-color: black;
  font-family: Helvetica;
  justify-content: center;
  font-size: 15px;
  color: #ffb310;
  border: 2px solid #ffb310;
  border-radius: 10px;
  width: 300px;
  display: flex;
  // border: 2px solid red;
  align-items: center;
  text-transform: lowercase;
  min-height: 60px;

  transition: color 0.4s, border-color 0.4s;

  &:active,
  &:hover,
  &:focus {
    background-color: #ffb310;
    color: black;
    border: 2px solid darken(#ffb310, 15%);
  }
  &:focus-visible {
    border-radius: 0.15em;
    outline: white solid 0.15em;
    outline-offset: 0.15em;
  }
`;
