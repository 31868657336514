import { MenuItem, TextField, styled } from "@mui/material";

// Styling for the drop down textfield
export const StyledTextField = styled(TextField)`
  min-width: 300px;
  display: flex;
  width: 304px;
  font-size: 15px;
  color: #ffb310;
  font-family: Helvetica;


  .MuiSelect-icon {
    color: #ffb310;
  }

  .MuiInputLabel-root {
    color: #ffb310;
    font-size: 15px;
    text-align: center;
  }

  .MuiInputLabel-shrink {
    color: #ffb310;
    outline: none;
  }

  .MuiOutlinedInput-root {
    border: 2px solid #ffb310;
    border-radius: 10px;
    min-height: 50px;
    height: 60px;
    justify-content: center;
    
  }

  & .MuiSelect-select {
    color: #ffb310;
    min-height: 50px;
    text-align: left;

    & .MuiInputBase-root {
      min-height: 50px;

      & .MuiOutlinedInput-input {
        min-height: 50px;
        padding: 0 14px;
      }
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 15px;
  font-family: Helvetica;
`;
