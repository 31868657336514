/* This file provides the data for the 'fake' rendered 2D Blog Post
 ** It acts as a blog post, although it routes the user to the TSX component for 2D.
 */

export const TwoDPost = {
  id: "twoD",
  title: "Standard 2D CAD Delivery",
  author: "Robotic Imaging",
  date: "2024-3-26",
  tags: ["As-Built Documentation", "Adaptive Reuse", "Scan-to-BIM"],
  mediaSrc:
    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2F2D-Blog-Header.png?alt=media&token=970e8e94-bfb9-4a84-9478-dfd6b4fda3c5",
  fakePost: true,
  excerpt:
    "2D CAD drawings have diverse applications, from architectural and mechanical design to structural analysis and construction documentation.",
  to: `/2D-blog`,
};
