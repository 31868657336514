/* This file provides the data for the 'fake' rendered Systems Blog Post
 ** It acts as a blog post, although it routes the user to the TSX component for Systems.
 */

export const SystemPost = {
  id: "system",
  title: "Robotic Imaging Data Exchange",
  author: "Robotic Imaging",
  date: "2024-3-28",
  tags: ["Adaptive Reuse", "Scan-to-BIM", "LiDAR", "As-Built Documentation"],
  mediaSrc:
    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FSystems-Blog-Header.png?alt=media&token=9d9baeea-a7a9-427e-ad71-c78ee24a864b",
  fakePost: true,
  excerpt:
    "Robotic Imaging deploys cyber-physical construction systems across the world to centralize as-built data that bridges the gap between the physical site and its digital twin.",
  to: `/system`,
};
