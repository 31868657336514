/* This file provides the data for the 'fake' rendered Drone Blog Post
 ** It acts as a blog post, although it routes the user to the TSX component for Drones.
 */

export const DronePost = {
  id: "drone",
  title: "Standard Drone Deliverables",
  author: "Robotic Imaging",
  date: "2024-3-24",
  tags: ["As-Built Documentation", "Drone", "Photogrammetry"],
  mediaSrc:
    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FStandard_Drone_Deliverables_Cover.png?alt=media&token=166d1a79-e729-4440-b64d-de7d0e1d6c87",
  fakePost: true,
  excerpt:
    "This article will give you an in depth understanding of Sample Aerial Drone services provided by Robotic Imaging.",
  to: `/aerial-drone`,
};
