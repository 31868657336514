import React from "react";
import kebabCase from "lodash/kebabCase";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { dateConversion, formatDateFromYMD, isMp4 } from "../utils/functions";
import NewsletterSignup from "../page-components/blog/NewsletterSignup/NewsletterSignup";
import CircleLogo from "../assets/logos/Circle_Logo.png";
import { ACTIVE_PAGE } from "../components/nav/utils";
import {
  ActionBar,
  MainContainer,
  BlogWrapper,
  BlogPost,
  BlogLink,
  CardImage,
  CardTitle,
  TagText,
  CardTagButton,
  CardExcerptText,
  TagLink,
  CardMP4,
} from "../page-components/blog/styles";
import { DronePost } from "../page-components/blog/FakePosts/DronePost";
import { SystemPost } from "../page-components/blog/FakePosts/SystemPost";
import { ThreeDPost } from "../page-components/blog/FakePosts/3DPost";
import { TwoDPost } from "../page-components/blog/FakePosts/2DPost";
import { VRPost } from "../page-components/blog/FakePosts/VRPost";

export default function BlogPage({ data }: { data: any }) {
  const { tags } = data.blog;

  const realPosts = data.blog.posts.map((post: any) => ({
    ...post,
    date: post.frontmatter.date,
  }));

  const fakePosts = [VRPost, ThreeDPost, SystemPost, TwoDPost, DronePost].map(
    (post) => ({
      ...post,
      date: formatDateFromYMD(post.date),
    })
  );

  // combine fake posts + real posts
  const combinedPosts = [...realPosts, ...fakePosts];

  // sort combined posts by date
  combinedPosts.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.BLOG}>
      <Seo
        title="Drone, BIM, LiDAR Information Blog | Robotic Imaging"
        description="Learning center for all things digital imaging - BIM, Drone, LiDAR, CAD, reality capture and much more."
      />
      <MainContainer>
        {/* Newsletter Sign Up + Drop Down Filters */}
        <ActionBar>
          <NewsletterSignup tags={tags} />
        </ActionBar>

        <BlogWrapper>
          {/* render blog with filtered posts */}
          {combinedPosts.map((post, index) => (
            <BlogPost key={post.id}>
              {post.fakePost ? (
                <>
                  <BlogLink to={post.to}>
                    <CardImage src={post.mediaSrc} alt="Cover" loading="lazy" />
                    <CardTitle>{post.title}</CardTitle>
                  </BlogLink>
                  <TagText>
                    Robotic Imaging, {formatDateFromYMD(post.date)}
                  </TagText>
                  <TagText>
                    Tags:{" "}
                    {post.tags.map(
                      (
                        tag:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | null
                          | undefined,
                        i: React.Key | null | undefined
                      ) => [
                        <CardTagButton type="button" key={i}>
                          <small>
                            <TagLink to={`/tags/${kebabCase(tag as string)}/`}>
                              {tag}
                            </TagLink>
                            {Number(i) < post.tags.length - 1 ? "" : ""}
                          </small>
                        </CardTagButton>,
                      ]
                    )}
                  </TagText>
                  <CardExcerptText>{post.excerpt}</CardExcerptText>
                </>
              ) : (
                <>
                  <BlogLink to={`/blog/${kebabCase(post.fields.slug)}/`}>
                    {isMp4(post.frontmatter.mediaSrc) ? (
                      <CardMP4
                        style={{
                          objectPosition: post.frontmatter.imageFocus,
                        }}
                      >
                        <source
                          src={post.frontmatter.mediaSrc}
                          type="video/mp4"
                        />
                      </CardMP4>
                    ) : (
                      <CardImage
                        className={`${
                          !post.frontmatter.mediaSrc ? "missing-media" : ""
                        }`}
                        src={post.frontmatter.mediaSrc || CircleLogo}
                        alt=""
                        style={{
                          objectPosition: post.frontmatter.imageFocus,
                        }}
                        loading="lazy"
                      />
                    )}

                    <CardTitle style={{ color: "black" }}>
                      {post.frontmatter.title}
                    </CardTitle>
                  </BlogLink>

                  <TagText>
                    Robotic Imaging, {dateConversion(post.frontmatter.date)}
                  </TagText>
                  <TagText>
                    Tags:{" "}
                    {post.frontmatter.tags.map(
                      (
                        tag:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | null
                          | undefined,
                        i: React.Key | null | undefined
                      ) => [
                        <CardTagButton type="button" key={i}>
                          <small>
                            <TagLink to={`/tags/${kebabCase(tag as string)}/`}>
                              {tag}
                            </TagLink>
                            {Number(i) < post.frontmatter.tags.length - 1
                              ? ""
                              : ""}
                          </small>
                        </CardTagButton>,
                      ]
                    )}
                  </TagText>
                  <CardExcerptText>{post.excerpt}</CardExcerptText>
                </>
              )}
            </BlogPost>
          ))}
        </BlogWrapper>
      </MainContainer>
    </SiteWrapper>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          author
          tags
          mediaSrc
          imageFocus
        }
        html
        excerpt
        id
      }
      tags: group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
