import React from "react";
import { navigate } from "gatsby";
import { kebabCase } from "lodash";
import { StyledMenuItem, StyledTextField } from "./FilterDropdown.styles";

interface FilterDropdownProps {
  tags: { fieldValue: string; totalCount: number }[];
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({ tags }) => {
  const handleChange = (event: any) => {
    const selectedTag = event.target.value;
    navigate(`/tags/${kebabCase(selectedTag)}`);
  };

  return (
    <StyledTextField select label="Filter Blog Posts" onChange={handleChange}>
      {tags.map((tag, i) => (
        <StyledMenuItem key={i} value={tag.fieldValue}>
          {tag.fieldValue}
        </StyledMenuItem>
      ))}
    </StyledTextField>
  );
};

export default FilterDropdown;
