import axios from "axios";
import React, { useState, useEffect } from "react";
import { mailingList } from "../../../utils/vars";
import {
  SignupFlexContainer,
  SignupPrompt,
  SignupWrapper,
  EmailInput,
  SignupButton,
} from "./NewsletterSignup.styles";
import FilterDropdown from "../FilterDropdown/FilterDropdown";

const SUBMITTED_TEXT = "Thanks! We'll be in touch shortly.";

const PHASES = {
  // START_ERASING: 1,
  START_TYPING: 1,
  FINISHED_TYPING: 2,
};

/**
 * Custom hook for prompt.
 * @param {boolean} isSubmitted - if form has been submitted.
 * @returns {string} prompt string.
 */
const usePrompt = (isSubmitted: boolean) => {
  const [prompt, setPrompt] = useState("");
  const [promptPhase, setPromptPhase] = useState(PHASES.START_TYPING);

  useEffect(() => {
    let sti: NodeJS.Timer;
    if (isSubmitted) {
      // If we are erasing, reduce string to 0.
      // if (promptPhase === PHASES.START_ERASING) {
      //   sti = setInterval(() => {
      //     setPrompt((prompt) =>
      //       prompt ? prompt.substring(0, Math.max(0, prompt.length - 5)) : ""
      //     );
      //   }, 77);
      // }

      // If we are writing, max out at target length.
      if (promptPhase === PHASES.START_TYPING) {
        sti = setInterval(() => {
          setPrompt((prompt) =>
            prompt.length !== SUBMITTED_TEXT.length
              ? SUBMITTED_TEXT.substring(
                  0,
                  Math.min(SUBMITTED_TEXT.length, prompt.length + 5)
                )
              : SUBMITTED_TEXT
          );
        }, 77);
      }
    }

    return () => {
      if (sti) {
        clearInterval(sti);
      }
    };
  }, [isSubmitted, promptPhase]);

  useEffect(() => {
    // Once at 0, start writing after pause.
    if (prompt === "") {
      setTimeout(() => {
        setPromptPhase(PHASES.START_TYPING);
      }, 400);
    }

    if (prompt === SUBMITTED_TEXT) {
      setPromptPhase(PHASES.FINISHED_TYPING);
    }
  }, [prompt]);

  return prompt;
};

const postForm = async (value: string) => {
  if (value) {
    try {
      await fetch(
        `https://api.formium.io/submit/604bcdd9edd5310001df915b/emailnewsletter`,
        {
          method: "POST",
          body: `New newsletter sign-up: ${value}`,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      axios.post(
        `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMail`,
        {
          destination: value,
          bcc: ["info@roboticimaging.com", ...mailingList],
          subject: "Robotic Imaging Newsletter Signup",
          text: `Thanks for signing up for the Robotic Imaging Newsletter.  We will be in touch with the latest technology, news and workflows.`,
          html: `<div>Thanks for signing up for the Robotic Imaging Newsletter.  We will be in touch with the latest technology, news and workflows.`,
        }
      );
    } catch (e) {
      console.log(e);
    }
  }
};

interface FilterDropdownProps {
  tags: { fieldValue: string; totalCount: number }[];
}

const NewsletterSignupReact: React.FC<FilterDropdownProps> = ({ tags }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const prompt = usePrompt(isSubmitted);
  const [value, setValue] = useState("");

  let className = "signup";
  if (isSubmitted) {
    className = `${className} signup--submitted`;
  }

  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        postForm(value);
        if (value) setIsSubmitted(true);
      }}
    >
      <SignupFlexContainer>
        <SignupPrompt>{prompt}</SignupPrompt>
        <SignupWrapper>
          <EmailInput
            type="text"
            placeholder="Sign up for newsletter..."
            value={value}
            name="e-mail"
            onChange={(e) => setValue(e.target.value)}
          />
        </SignupWrapper>

        <SignupButton type="submit">Sign up</SignupButton>
        <FilterDropdown tags={tags} />
      </SignupFlexContainer>
    </form>
  );
};

export default NewsletterSignupReact;
