/* This file provides the data for the 'fake' rendered 3D Blog Post
 ** It acts as a blog post, although it routes the user to the TSX component for 3D.
 */

export const ThreeDPost = {
  id: "threeD",
  title: "Standard 3D Revit Delivery",
  author: "Robotic Imaging",
  date: "2024-3-31",
  tags: ["As-Built Documentation", "3D Walkthrough", "Scan-to-BIM"],
  mediaSrc:
    "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2F3D-Blog-Header.png?alt=media&token=50ed4e89-5433-454a-9e3f-e5df5044f3e3",
  fakePost: true,
  excerpt:
    "We collect 3D laser scans specific to project goals. This data improves workflows, resulting in more accurate and realistic models. The information collected by our technicians is then passed on to our BIM team, who create highly detailed 3D models.",
  to: `/3D-blog`,
};
